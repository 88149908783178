import React from "react";
import { Vendor } from "types/types";
import LoadableImage from "./LoadableImage";
import "./VendorCard.scss";

interface VendorCardProps {
  vendor: Vendor;
}

const VendorCard: React.FC<VendorCardProps> = ({ vendor }) => {
  return (
    <div className="VendorCard">
      <div className="VendorCardImage">
        <LoadableImage src={vendor.profileUrl} alt={vendor.name} />
      </div>
      <div className="VendorCardContent">
        <div className="VendorCardTitle">{vendor.name}</div>
      </div>
    </div>
  );
};

export default VendorCard;
