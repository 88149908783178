import React from "react";
import { useHistory } from "react-router-dom";
import { Reward, Routes } from "types/types";
import RewardCard from "./RewardCard";
import "./RewardList.scss";

interface RewardListProps {
  rewards: Reward[];
}


function RewardList(props: RewardListProps) {
  const history = useHistory();
  const { rewards } = props;
  return (
    <div className="RewardList">
      {rewards.map((reward) => (
        <div
          className="RewardListItem"
          onClick={() => {
            history.push(Routes.REWARD.replace(":id", reward.id.toString()));
          }}
          key={reward.id}
        >
          <RewardCard reward={reward} />
        </div>
      ))}
    </div>
  );
}

export default RewardList;
