import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppContext } from "state/context";
import VendorList from "components/VendorList";
import LoadableImage from "components/LoadableImage";
import "./Stamp.scss";

interface ParamTypes {
  id: string;
}

function StampPage() {
  const { t } = useTranslation("stamp");
  const { id } = useParams<ParamTypes>();
  const { state } = useContext(AppContext);
  const { stamps, vendors } = state;
  const stamp = stamps.find((stamp) => stamp.id === parseInt(id));
  const vendorsWithStamp = stamp
    ? vendors.filter((vendor) => vendor.stampIds.includes(stamp.id))
    : [];

  return (
    <div className="Stamp">
      {stamp ? (
        <div>
          <div className="StampImage">
            <LoadableImage src={stamp.imgUrl} alt={stamp.name} />
          </div>
          <h3>{stamp.name}</h3>
          <div className="StampDescription">{stamp.description}</div>
          <hr />
          <div className="StampVendorsTitle">{t("availableFromTitle")}</div>
          <VendorList vendors={vendorsWithStamp} />
        </div>
      ) : (
        <div>{t("errorDoesntExist")}</div>
      )}
    </div>
  );
}

export default StampPage;
