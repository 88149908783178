import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import "./StampsQrScanner.scss";

// eslint-disable-next-line import/no-webpack-loader-syntax
import qrScannerWorkerPath from "!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = qrScannerWorkerPath;

interface StampsQrScannerProps {
  onScan(x: string): void;
}

function getCamera() {
  if (!navigator.mediaDevices) return Promise.resolve([]);
  let openedStream: any = null;
  return (
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => (openedStream = stream))
      // Fail gracefully, especially if the device has no camera or on mobile when the camera is already in
      // use and some browsers disallow a second stream.
      .catch(() => {})
      .then(() => openedStream.getTracks()[0].getSettings())
      .finally(() => {
        // close the stream we just opened for getting camera access for listing the device labels
        if (!openedStream) return;
        for (const track of openedStream.getTracks()) {
          track.stop();
          openedStream.removeTrack(track);
        }
      })
  );
}

const StampsQrScanner: React.FC<StampsQrScannerProps> = ({ onScan }) => {
  const [camera, setCamera] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const videoEl = useRef() as React.MutableRefObject<HTMLVideoElement>;

  useEffect(() => {
    if (!camera) {
      (async () => {
        const camera = await getCamera();
        setCamera(camera);
      })();
    }
  }, [camera]);

  useEffect(() => {
    if (camera) {
      const ratio = camera.width / window.innerWidth;
      console.log("Camera", camera.width, camera.height, ratio);

      const qrScanner = new QrScanner(
        videoEl.current,
        (result) => {
          onScan(result);
          qrScanner.stop();
          console.log("scanned", result);
        },
        (error) => {
          //console.log("QR Scanning error", error);
        },
        (video) => {
          const smallestDimension = Math.min(
            video.videoWidth,
            video.videoHeight
          );

          const scanRegionSize = smallestDimension;
          const sizes = {
            x: Math.round((video.videoWidth - scanRegionSize) / 2),
            y: Math.round((video.videoHeight - scanRegionSize) / 2),
            width: scanRegionSize,
            height: scanRegionSize
          };
          return sizes;
        }
      );
      qrScanner.start();

      return () => {
        qrScanner.stop();
      };
    }
  }, [onScan, camera]);

  return (
    <div className="StampsQrScanner">
      <video ref={videoEl}></video>
    </div>
  );
};

export default StampsQrScanner;
