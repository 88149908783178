// import React, { createContext, useReducer } from "react";
// import { StampActions } from "state/actions/stamps.actions";

import {
  Stamp,
  User,
  UserStamp,
  Vendor,
  Challenge,
  Reward,
  ClaimedReward,
  Language
} from "types/types";

// const initialState = {};
// const AppContext = createContext(initialState);

// const reducer = (state: typeof initialState, action: StampActions) => {
//   switch (action.type) {
//     default:
//       return { ...state };
//   }
// };

// const StateProvider: React.FC = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, initialState);

//   return (
//     <AppContext.Provider value={{ state, dispatch }}>
//       {children}
//     </AppContext.Provider>
//   );
// };

// export { store, StateProvider };

export interface AppState {
  user: User;
  vendors: Vendor[];
  stamps: Stamp[];
  userStamps: UserStamp[];
  challenges: Challenge[];
  rewards: Reward[];
  claimedRewards: ClaimedReward[];
  appLoading: boolean;
  language: Language;
}

export const initialAppState: AppState = {
  user: {} as User,
  vendors: [],
  stamps: [],
  userStamps: [],
  challenges: [],
  rewards: [],
  claimedRewards: [],
  appLoading: false,
  language: Language.Japanese
};
