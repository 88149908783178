import React, { useContext, useEffect, useState } from "react";
import StampList from "components/StampList";
import { AppContext } from "state/context";

function Stamps() {
  const { state } = useContext(AppContext);
  const { stamps } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(false);
    setError(undefined);
  }, []);

  if (error) {
    return <div>Ops! Something went wrong.</div>;
  }

  return (
    <div>
      <div>{isLoading ? "Loading..." : <StampList stamps={stamps} />}</div>
    </div>
  );
}

export default Stamps;
