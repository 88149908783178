import {
  Challenge,
  ClaimedReward,
  Language,
  Reward,
  User,
  UserStamp,
  Vendor
} from "types/types";
import { StampsActions, StampsActionTypes } from "./stamps.actions";

// AppLoading

export enum AppLoadingActionTypes {
  AppLoadingUpdate = "apploading/update"
}

export interface AppLoadingUpdate {
  type: AppLoadingActionTypes.AppLoadingUpdate;
  payload: boolean;
}

export type AppLoadingActions = AppLoadingUpdate;

// Language

export enum LanguageActionTypes {
  LanguageUpdate = "language/update"
}

export interface LanguageUpdate {
  type: LanguageActionTypes.LanguageUpdate;
  payload: Language;
}

export type LanguageActions = LanguageUpdate;

// User

export enum UserActionTypes {
  UserUpdate = "user/update",
  UserStampsUpdate = "user/stamps/update"
}

export interface UserUpdate {
  type: UserActionTypes.UserUpdate;
  payload: User;
}
export interface UserStampsUpdate {
  type: UserActionTypes.UserStampsUpdate;
  payload: UserStamp[];
}

export type UserActions = UserUpdate | UserStampsUpdate;

// Vendors

export enum VendorsActionTypes {
  VendorsUpdate = "vendors/update"
}

export interface VendorsUpdate {
  type: VendorsActionTypes.VendorsUpdate;
  payload: Vendor[];
}

export type VendorsActions = VendorsUpdate;

// Challenges

export enum ChallengesActionTypes {
  ChallengesUpdate = "challenges/update"
}

export interface ChallengesUpdate {
  type: ChallengesActionTypes.ChallengesUpdate;
  payload: Challenge[];
}

export type ChallengesActions = ChallengesUpdate;

// Rewards
export enum RewardsActionTypes {
  RewardsUpdate = "rewards/update"
}

export interface RewardsUpdate {
  type: RewardsActionTypes.RewardsUpdate;
  payload: Reward[];
}

export type RewardsActions = RewardsUpdate;

// ClaimedRewards
export enum ClaimedRewardsActionTypes {
  ClaimedRewardsUpdate = "claimedRewards/update"
}

export interface ClaimedRewardsUpdate {
  type: ClaimedRewardsActionTypes.ClaimedRewardsUpdate;
  payload: ClaimedReward[];
}

export type ClaimedRewardsActions = ClaimedRewardsUpdate;

// Globals

export const AppActionTypes = {
  ...AppLoadingActionTypes,
  ...LanguageActionTypes,
  ...StampsActionTypes,
  ...UserActionTypes,
  ...VendorsActionTypes,
  ...ChallengesActionTypes,
  ...RewardsActionTypes,
  ...ClaimedRewardsActionTypes
};

export type AppActions =
  | AppLoadingActions
  | LanguageActions
  | StampsActions
  | UserActions
  | VendorsActions
  | ChallengesActions
  | RewardsActions
  | ClaimedRewardsActions;
