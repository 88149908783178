import React, { useCallback, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as api from "services/apis";
import Main from "Main";
import { appReducer } from "state/reducers/reducer";
import { initialAppState } from "state/state";
import { AppContext } from "state/context";
import { AppActionTypes } from "state/actions/actions";
import "./App.scss";
import { Language, Routes, User } from "types/types";

const RoutesAuthNotRequiredList: string[] = [Routes.SIGNIN, Routes.SIGNUP];

const App: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();

  const [state, dispatch] = useReducer(appReducer, initialAppState);

  useEffect(() => {
    // Load language preference from localStorage
    const language = (localStorage.getItem("language") ||
      Language.Japanese) as Language;
    dispatch({ type: AppActionTypes.LanguageUpdate, payload: language });
  }, []);

  const signIn = useCallback(async (userName: string, password: string) => {
    try {
      const response = await api.signIn(userName, password);
      console.log("Login", response);
      const user: User = {
        email: userName,
        token: response.token
      };

      localStorage.setItem("kammeko-user", JSON.stringify(user));

      dispatch({ type: AppActionTypes.UserUpdate, payload: user });
      return user;
    } catch (error) {
      throw error;
    }
  }, []);

  const signUp = useCallback(async (userName: string, password: string) => {
    try {
      const response = await api.signUp(userName, password);
      console.log("Sign up", response);
      const user: User = {
        email: userName,
        token: response.token
      };

      localStorage.setItem("kammeko-user", JSON.stringify(user));

      dispatch({ type: AppActionTypes.UserUpdate, payload: user });
      return user;
    } catch (error) {
      throw error;
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      localStorage.removeItem("kammeko-user");
      dispatch({ type: AppActionTypes.UserUpdate, payload: {} as User });
      history.push(Routes.SIGNIN);
    } catch (error) {}
  }, [history]);

  const loadData = (user: User, withDispatch = true) => {
    withDispatch &&
      dispatch({ type: AppActionTypes.AppLoadingUpdate, payload: true });
    return Promise.all([
      api.getVendors(user),
      api.getStamps(user),
      api.getUserStamps(user),
      api.getChallenges(user),
      api.getRewards(user),
      api.getClaimedRewards(user)
    ])
      .then((results) => {
        const [
          vendors,
          stamps,
          userStamps,
          challenges,
          rewards,
          claimedRewards
        ] = results;

        dispatch({ type: AppActionTypes.VendorsUpdate, payload: vendors });

        dispatch({ type: AppActionTypes.StampsUpdate, payload: stamps });
        dispatch({
          type: AppActionTypes.UserStampsUpdate,
          payload: userStamps
        });
        dispatch({
          type: AppActionTypes.ChallengesUpdate,
          payload: challenges
        });
        dispatch({
          type: AppActionTypes.RewardsUpdate,
          payload: rewards
        });

        dispatch({
          type: AppActionTypes.ClaimedRewardsUpdate,
          payload: claimedRewards
        });
      })
      .catch((error) => {
        console.log("Api error", error);
      })
      .finally(() => {
        withDispatch &&
          dispatch({ type: AppActionTypes.AppLoadingUpdate, payload: false });
      });
  };

  useEffect(() => {
    api.onAuthenticationRequired(() => {
      console.log("handling auth required");
      localStorage.removeItem("kammeko-user");
      dispatch({ type: AppActionTypes.UserUpdate, payload: {} as User });
      history.push(Routes.SIGNIN);
    });
  }, [history]);

  useEffect(() => {
    // Redirect to login if we don't have a token saved
    if (
      RoutesAuthNotRequiredList.includes(location.pathname) ||
      state.user.token
    ) {
      return;
    }

    console.log("Location", location.pathname, RoutesAuthNotRequiredList);
    const savedUser = localStorage.getItem("kammeko-user");
    if (savedUser) {
      const user: User = JSON.parse(savedUser);
      dispatch({ type: AppActionTypes.UserUpdate, payload: user });
    } else {
      history.push(Routes.SIGNIN);
    }
  }, [history, location.pathname, state.user.token]);

  useEffect(() => {
    (async () => {
      console.log("Token", state.user.token);
      if (state.user.token) {
        loadData(state.user);
      }
    })();
  }, [state.user]);

  useEffect(() => {
    // Whenever the user changes the language, load the language locale file
    i18n.changeLanguage(state.user.language);
  }, [i18n, state.user.language]);

  return (
    <AppContext.Provider
      value={{ state, dispatch, signIn, signUp, signOut, loadData }}
    >
      <Main />
    </AppContext.Provider>
  );
};

export default App;
