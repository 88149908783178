import React from "react";
import { User } from "types/types";
import { AppActions } from "./actions/actions";
import { AppState, initialAppState } from "./state";

export const AppContext = React.createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppActions>;
  signIn: Function;
  signUp: Function;
  signOut: Function;
  loadData: (user: User, withDispatch: boolean) => any;
}>({
  state: initialAppState,
  dispatch: () => undefined,
  signIn: () => undefined,
  signUp: () => undefined,
  signOut: () => undefined,
  loadData: (user: User, withDispatch: boolean) => undefined
});
