import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import InfoIcon from "@material-ui/icons/Info";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LockIcon from "@material-ui/icons/Lock";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AppContext } from "state/context";
import "./Menu.scss";
import LanguageSwitch from "./LanguageSwitch";

type MenuLink = {
  key: string;
  label: string;
  link: string;
  icon?: ReactElement | null;
};

type SocialLink = {
  key: string;
  label: string;
  link: string;
};

const socialLinks: SocialLink[] = [
  {
    key: "facebook",
    label: "Facebook",
    link: "https://facebook.com/kammekolife/"
  },
  {
    key: "instagram",
    label: "Instagram",
    link: "https://www.instagram.com/kammekolife/"
  }
];

interface MenuProps {
  isOpen: boolean;
  onClose(): void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("menu");
  const { signOut } = useContext(AppContext);


  const handleSignout = () => {
    signOut();
  };

  const links: MenuLink[] = [
    {
      key: "contact-us",
      label: t("contactUs"),
      link: "https://www.kammeko.com/contact",
      icon: <HeadsetMicIcon />
    },
    {
      key: "about-kammeko",
      label: t("about"),
      link: "https://www.kammeko.com/aboutus",
      icon: <InfoIcon />
    },
    {
      key: "how-to-use",
      label: t("howToUse"),
      link: "https://www.kammeko.com/how-to-use",
      icon: <MenuBookIcon />
    },
    {
      key: "privacy-policy",
      label: t("privacyPolicy"),
      link: t("links.privacyPolicyLink"),
      icon: <LockIcon />
    },
    {
      key: "terms-conditions",
      label: t("termsAndConditions"),
      link: t("links.termsAndConditionsLink"),
      icon: <ErrorOutlineIcon />
    }
  ];

  return (
    <div className="Menu">
      <Drawer anchor="left" open={isOpen} onClose={onClose}>
        <IconButton
          aria-label="scan-qr"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="MenuContent">
          <LanguageSwitch />

          <List>
            {links.map((link) => (
              <ListItem
                component="a"
                href={link.link}
                target="_blank"
                disableGutters
              >
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.label} disableTypography />
                <IconButton size="small" aria-label="comments">
                  <OpenInNewIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>

          <div className="MenuButtonLogout" onClick={handleSignout}>
            <ExitToAppIcon />
            <span>{t("logout")}</span>
          </div>

          <div className="MenuSocialLinks">
            {socialLinks.map((socialLink) => (
              <a
                className="MenuSocialLink "
                href={socialLink.link}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className={`MenuSocialLinkIcon MenuSocialLinkIcon-${socialLink.key}`}
                />
                <span>{socialLink.label}</span>
              </a>
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
