import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { AppContext } from "state/context";
import { Claim } from "types/types";
import StampList from "components/StampList";
import "./CollectStampsConfirmation.scss";

interface CollectStampsConfirmationProps {
  claim: Claim;
  onClose(): void;
}

const CollectStampsConfirmation: React.FC<CollectStampsConfirmationProps> = (
  props
) => {
  const { t } = useTranslation("collectStampsConfirmation");
  const { state } = useContext(AppContext);
  const { stamps } = state;
  const { claim, onClose } = props;

  const collectedStamps = stamps.filter((stamp) => {
    return claim.stampIds.includes(stamp.id);
  });

  return (
    <div className="CollectStampsConfirmation">
      <div className="CollectStampsConfirmationContent">
        <h3>{t("title")}</h3>
        <div
          className="CollectStampsConfirmationDescription"
          dangerouslySetInnerHTML={{ __html: t("description") }}
        ></div>
        <StampList stamps={collectedStamps} column />
      </div>
      <div className="CollectStampsConfirmationFooter">
        <Button onClick={onClose}>{t("button")}</Button>
      </div>
    </div>
  );
};

export default CollectStampsConfirmation;
