import React from "react";
import { useTranslation } from "react-i18next";
import { Reward } from "types/types";
import "./RewardCard.scss";

interface RewardCardProps {
  reward: Reward;
}

const RewardCard: React.FC<RewardCardProps> = ({ reward }) => {
  const { t } = useTranslation("rewardCard");

  return (
    <div className={`RewardCard ${reward.redeemed && "RewardCard-redeemed"}`}>
      <div className="RewardCardTitle">{reward.name}</div>
      <div className="RewardCardDescription">{reward.shortDescription}</div>
      {reward.redeemed && <div className="RewardCardRedeemed">{t("redeemed")}</div>}
    </div>
  );
};

export default RewardCard;
