import React, { FC, useContext } from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import { AppActionTypes } from "state/actions/actions";
import { Language } from "types/types";
import "./LanguageSwitch.scss"

type Props = {
  size?: string
}

const LanguageSwitch: FC<Props> = ({ size }) => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = state;
  const selectedLanguage = user.language;
  const { t } = useTranslation("menu");


  const handleLanguageSwitch = (languageKey: Language) => {
    localStorage.setItem("language", languageKey);

    dispatch({
      type: AppActionTypes.LanguageUpdate,
      payload: languageKey
    });
  };

  const languages = [
    {
      key: Language.Japanese,
      label: size === 'small' ? t("languageSwitchSmall.japanese") : t("languageSwitch.japanese")
    },
    {
      key: Language.English,
      label: size === 'small' ? t("languageSwitchSmall.english") : t("languageSwitch.english")
    }
  ];


  return (
    <div className="MenuLanguageSwitch">
      {languages.map((language) => (
        <span
          key={language.key}
          onClick={() => handleLanguageSwitch(language.key)}
          data-language={language.key}
          aria-selected={selectedLanguage === language.key}
        >
          {language.label}
        </span>
      ))}
    </div>
  )
}

export default LanguageSwitch