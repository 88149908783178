import React, { useContext, useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { claimStamps } from "services/apis";
import { Claim, User } from "types/types";
import { AppContext } from "state/context";
import CollectStampsConfirmation from "components/CollectStampsConfirmation";
import StampsQrScanner from "components/StampsQrScanner";
import "./CollectStamps.scss";
import Loadable from "components/Loadable";
import { useLoad } from "state/hooks/useLoad";

interface CollectStampsProps {
  onClose(): void;
}

const CollectStamps: React.FC<CollectStampsProps> = ({ onClose }) => {
  const [payload, setPayload] = useState<string | null>(null);
  const [areStampsClaimed, setAreStampsClaimed] = useState(false);
  const [claim, setClaim] = useState<Claim>({} as Claim);
  const { state, loadData } = useContext(AppContext);
  const { user } = state;

  const claimAndReload = async (user: User, payload: string) => {
    try {
      const result = await claimStamps(user, payload);
      loadData(user, false);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const [triggerClaimAndReload, { isLoading, isError }] = useLoad(
    claimAndReload,
    1000
  );

  const triggerClaimAndReloadRef = useRef(triggerClaimAndReload);

  useEffect(() => {
    if (payload && user) {
      (async () => {
        try {
          const claim = await triggerClaimAndReloadRef.current(user, payload);
          setClaim(claim as Claim);
          setAreStampsClaimed(true);
        } catch (error) {}
      })();
    }
  }, [payload, user]);

  return (
    <Loadable isLoading={isLoading} isError={isError}>
      {areStampsClaimed ? (
        <CollectStampsConfirmation claim={claim as Claim} onClose={onClose} />
      ) : (
        <div className="CollectStamps">
          <IconButton
            aria-label="scan-qr"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="CollectStampsContent">
            {!payload && (
              <StampsQrScanner
                onScan={(payload) => {
                  setPayload(payload);
                }}
              />
            )}
          </div>
        </div>
      )}
    </Loadable>
  );
};

export default CollectStamps;
