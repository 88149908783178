import React, { useState } from "react";
import { AppBar, Dialog, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Navigation from "components/Navigation";
import Menu from "components/Menu";
import "./Header.scss";
import CollectStamps from "./CollectStamps";
import DialogTransition from "./DialogTransition";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCollectOpen, setIsCollectOpen] = useState(false);

  return (
    <div className="Header">
      <Menu
        isOpen={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      />
      <Dialog
        fullScreen
        open={isCollectOpen}
        onClose={() => {}}
        TransitionComponent={DialogTransition}
      >
        <CollectStamps
          onClose={() => {
            setIsCollectOpen(false);
          }}
        />
      </Dialog>
      <AppBar elevation={0} color="transparent" position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setIsMenuOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            aria-label="scan-qr"
            onClick={() => {
              setIsCollectOpen(true);
            }}
          >
            <CropFreeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Navigation />
    </div>
  );
};

export default Header;
