import React from "react";
import { useHistory } from "react-router-dom";
import { Routes, Vendor } from "types/types";
import VendorCard from "components/VendorCard";
import "./VendorList.scss";

interface VendorListProps {
  vendors: Vendor[];
}

function VendorList(props: VendorListProps) {
  const history = useHistory();
  const { vendors } = props;

  return (
    <div className="VendorList">
      {vendors.map((vendor) => (
        <div
          className="VendorListItem"
          onClick={() => {
            history.push(Routes.VENDOR.replace(":id", vendor.id.toString()));
          }}
          key={vendor.id}
        >
          <VendorCard vendor={vendor} />
        </div>
      ))}
    </div>
  );
}

export default VendorList;
