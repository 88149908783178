import React, { useContext, useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { redeemReward } from "services/apis";
import { Challenge, Vendor, ClaimedReward, Reward, User } from "types/types";
import { AppContext } from "state/context";
import { useLoad } from "state/hooks/useLoad";
import RedeemRewardConfirmation from "./RedeemRewardConfirmation";
import StampsQrScanner from "./StampsQrScanner";
import Loadable from "components/Loadable";
import "./CollectStamps.scss";

interface Props {
  claimedReward: ClaimedReward;
  reward: Reward;
  challenge: Challenge;
  onClose(): void;
}

const RedeemReward: React.FC<Props> = ({ claimedReward, reward, onClose }) => {
  const [payload, setPayload] = useState<string | null>(null);
  const [redeemedReward, setRedeemedReward] = useState<ClaimedReward | null>(
    null
  );
  const { state, loadData } = useContext(AppContext);
  const { user, vendors } = state;
  const [vendor, setVendor] = useState<Vendor | null>(null);

  const redeemAndReload = async (
    user: User,
    rewardId: number,
    payload: string
  ) => {
    try {
      const redeemedReward: ClaimedReward = await redeemReward(
        user,
        rewardId,
        payload
      );
      if (redeemedReward) {
        await loadData(user, false);

        const vendorId = redeemedReward.vendorId as number;
        const vendor = vendors.find(
          (vendor) => vendor.id.toString() === vendorId.toString()
        );
        setVendor(vendor as Vendor);
        setRedeemedReward(redeemedReward);
      }
      return redeemedReward;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const [triggerRedeemAndReload, { isLoading, isError }] = useLoad(
    redeemAndReload,
    1000
  );

  // Prevent having to add triggerClaimReward as dependency of useEffect,
  // which leads to infinite loop
  const triggerRedeemAndReloadRef = useRef(triggerRedeemAndReload);

  useEffect(() => {
    if (payload && user && vendors) {
      (async () => {
        try {
          const redeemedReward = await triggerRedeemAndReloadRef.current(
            user,
            claimedReward.id,
            payload
          );
          console.log(redeemedReward);
        } catch (error) {}
      })();
    }
  }, [payload, user, vendors, claimedReward]);

  if (redeemedReward) {
    return (
      <RedeemRewardConfirmation
        reward={reward}
        vendor={vendor as Vendor}
        date={redeemedReward.updatedAt}
      />
    );
  }

  return (
    <div className="CollectStamps">
      <Loadable isLoading={isLoading} isError={isError}>
        <IconButton
          aria-label="scan-qr"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="CollectStampsContent">
          {!payload && (
            <StampsQrScanner
              onScan={(payload) => {
                setPayload(payload);
              }}
            />
          )}
        </div>
      </Loadable>
    </div>
  );
};

export default RedeemReward;
