import React from "react";
import LoadableImage from "./LoadableImage";
import "./ChallengeImage.scss";

interface Props {
  src: string;
  alt: string;
  big?: boolean;
}

const ChallengeImage: React.FC<Props> = ({ src, alt, big }) => {
  return (
    <div className={`ChallengeImage-root ${big && "ChallengeImage-big"}`}>
      <LoadableImage src={src} alt={alt} />
    </div>
  );
};

export default ChallengeImage;
