import React, { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  useLocation,
  Redirect
} from "react-router-dom";
import Signup from "components/Signup";
import Vendors from "components/Vendors";
import Stamps from "components/Stamps";
import Challenge from "components/Challenge";
import Challenges from "components/Challenges";
import Reward from "components/Reward";
import Rewards from "components/Rewards";
import Header from "components/Header";
import Stamp from "components/Stamp";
import Vendor from "components/Vendor";
import ClaimReward from "components/ClaimReward";
import Onboarding from "components/Onboarding";
import { Routes } from "types/types";
import "./Main.scss";
import Signin from "components/Signin";
import Loadable from "components/Loadable";
import { AppContext } from "state/context";
import DesktopAlert from "components/DesktopAlert";

const noHeaderRoutes: string[] = [
  Routes.COLLECT,
  Routes.SIGNUP,
  Routes.SIGNIN,
  Routes.CLAIM
];

const noOnboardingRoutes: string[] = [Routes.SIGNUP, Routes.SIGNIN];

const Main: React.FC<RouteComponentProps> = (props) => {
  const { pathname } = useLocation();
  const { state } = useContext(AppContext);
  const { appLoading } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const shouldSeeOnboarding =
    localStorage.getItem("hasSeenOnboarding") !== "true" &&
    !noOnboardingRoutes.includes(props.location.pathname);

  return (
    <div className="Main">
      {shouldSeeOnboarding && <Onboarding />}
      <Loadable isLoading={appLoading} isError={false}>
        {!noHeaderRoutes.includes(props.location.pathname) && <Header />}
        <Switch>
          <Route path={Routes.SIGNUP}>
            <Signup />
          </Route>{" "}
          <Route path={Routes.SIGNIN}>
            <Signin />
          </Route>
          <Route path={Routes.VENDOR}>
            <Vendor />
          </Route>
          <Route path={Routes.VENDORS}>
            <Vendors />
          </Route>
          <Route path={Routes.STAMP}>
            <Stamp />
          </Route>
          <Route path={Routes.STAMPS}>
            <Stamps />
          </Route>
          <Route path={Routes.CHALLENGE}>
            <Challenge />
          </Route>
          <Route path={Routes.CHALLENGES}>
            <Challenges />
          </Route>
          <Route path={Routes.REWARD}>
            <Reward />
          </Route>
          <Route path={Routes.REWARDS}>
            <Rewards />
          </Route>
          <Route path={Routes.CLAIM}>
            <ClaimReward />
          </Route>
          <Route path={Routes.HOME}>
            <Redirect to={Routes.STAMPS} />
          </Route>
        </Switch>
      </Loadable>
      <DesktopAlert />
    </div>
  );
};

export default withRouter(Main);
