import { useState } from "react";

export function useLoad<T>(
  functionToLoad: (...args: any[]) => Promise<T>,
  minDelay: number = 0
) {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const trigger = async (...args: any[]) => {
    const startTime = Date.now();
    try {
      setIsLoading(true);
      const data = await functionToLoad(...args);
      setData(data);

      // Sleeps if the elapsed time is less than the given minimum delay
      const elapsedTime = Date.now() - startTime;
      const remainingTime = minDelay - elapsedTime;
      await new Promise((resolve) => setTimeout(resolve, remainingTime));

      return data;
    } catch (error) {
      setIsError(true);
      setErrorMessage((error as Error).message);
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }

    return null;
  };
  return [trigger, { data, isLoading, isError, errorMessage }] as const;
}
