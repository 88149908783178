import React from "react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  percent: number;
  dark?: boolean;
  tall?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, dark, tall }) => {
  return (
    <div
      className={`ProgressBar ${dark && "ProgressBar-dark"} ${
        tall && "ProgressBar-tall"
      }`}
      style={{ "--progress": `${percent}%` } as any}
    >
      <span></span>
    </div>
  );
};

export default ProgressBar;
