import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Dialog } from "@material-ui/core";
import { format } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { AppContext } from "state/context";
import { Challenge, ClaimedReward, Reward, Vendor } from "types/types";
import VendorList from "components/VendorList";
import ChallengeCard from "components/ChallengeCard";
import RewardCard from "components/RewardCard";
import RedeemReward from "components/RedeemReward";
import DialogTransition from "components/DialogTransition";
import "./Reward.scss";

interface ParamTypes {
  id: string;
}

interface DateLanguage {
  locale: Locale,
  format: string
}

const dateLanguageMap: { [key: string]: DateLanguage } = {
  'en': {
    locale: enUS,
    format: 'MMMM dd, yyyy'
  },
  'ja': {
    locale: ja,
    format: "PPP"
  }
}

function RewardPage() {
  const { id } = useParams<ParamTypes>();
  const { t } = useTranslation("rewardPage");
  const { state } = useContext(AppContext);
  const { rewards, claimedRewards, vendors, challenges, user } = state;
  const [reward, setReward] = useState<Reward | null>(null);
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [claimedReward, setClaimedReward] = useState<ClaimedReward | null>(
    null
  );
  const [vendorsWithReward, setVendorsWithReward] = useState<Vendor[]>([]);
  const [isRedeemOpen, setIsRedeemOpen] = useState<boolean>(false);

  const handleRedeemClick = () => {
    setIsRedeemOpen(true);
  };

  useEffect(() => {
    if (rewards) {
      const reward = rewards.find(
        (reward) => reward.id === parseInt(id)
      ) as Reward;
      setReward(reward);
    }
  }, [rewards, id]);

  useEffect(() => {
    if (reward && challenges && vendors) {
      const challenge = challenges.find(
        (challenge) => reward.challengeId === challenge.id
      ) as Challenge;
      setChallenge(challenge);
      setVendorsWithReward(vendors);
    }
  }, [reward, vendors, challenges]);

  useEffect(() => {
    if (!claimedReward && reward && claimedRewards) {
      const claimedReward = claimedRewards.find(
        (claimedReward) => claimedReward.rewardId === reward.id
      ) as ClaimedReward;

      setClaimedReward(claimedReward);

    }
  }, [reward, claimedReward, claimedRewards]);

  const formatRedeemDate = (date: number, language = 'en') => {
    const dateLanguage = dateLanguageMap[language];
    return format(new Date(date), dateLanguage.format, { locale: dateLanguage.locale });
  }


  return (
    <div className="Reward">
      {reward && (
        <div>
          <RewardCard reward={reward} />
          <div className="RewardDescription">{reward.description}</div>

          {reward.redeemed && reward.redeemedOn ? (
            <div className="RewardRedeemedInfo">
              {t("redeemedOn", { date: formatRedeemDate(reward.redeemedOn, user.language) })}
            </div>
          ) : (
            claimedReward && (
              <Button onClick={handleRedeemClick}>{t("redeemButton")}</Button>
            )
          )}
          <hr />
          {challenge && (
            <>
              <div className="RewardVendorsTitle">
                {t("linkedChallengeTitle")}
              </div>
              <ChallengeCard challenge={challenge as Challenge} />
              <hr />
            </>
          )}
          <div className="RewardVendorsTitle">{t("availableAtTitle")}</div>
          <VendorList vendors={vendorsWithReward} />
          <Dialog
            fullScreen
            open={isRedeemOpen}
            onClose={() => { }}
            TransitionComponent={DialogTransition}
          >
            <RedeemReward
              claimedReward={claimedReward as ClaimedReward}
              reward={reward}
              challenge={challenge as Challenge}
              onClose={() => {
                setIsRedeemOpen(false);
              }}
            />
          </Dialog>
        </div>
      )}
    </div>
  );
}

export default RewardPage;
