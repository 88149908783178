import React from "react";
import { useHistory } from "react-router-dom";
import { Routes, Stamp, StampListItem } from "types/types";
import classNames from "classnames";
import StampCard from "./StampCard";
import StampCardWide from "./StampCardWide";
import "./StampList.scss";

interface StampListProps {
  stamps: StampListItem[];
  column?: boolean;
}

function StampList(props: StampListProps) {
  const history = useHistory();
  const { stamps, column } = props;

  console.log("stamp list items", stamps);

  return (
    <div className={classNames("StampList", { "StampList-Column": column })}>
      {stamps.map((stamp, i) => (
        <div
          className="StampListItem"
          onClick={() => {
            history.push(Routes.STAMP.replace(":id", stamp.id.toString()));
          }}
          key={i}
        >
          {console.log("Stamp list item", stamp)}
          {column ? (
            <StampCardWide stamp={stamp as Stamp} />
          ) : (
            <StampCard stamp={stamp as Stamp} disabled={stamp.disabled} />
          )}
        </div>
      ))}
      {[...Array(4)].map((item, i) => (
        <div className="StampListItem" key={i}></div>
      ))}
    </div>
  );
}

export default StampList;
