import React from "react";
import LoadableImage from "components/LoadableImage";
import rewardIcon from "assets/reward-icon.svg";
import { useTranslation } from "react-i18next";
import "./RewardEmpty.scss";

export default function RewardEmpty() {
  const { t } = useTranslation("rewards");

  return (
    <div className="RewardEmpty">
      <div className="RewardEmptyImage">
        <LoadableImage src={rewardIcon} alt={"No Reward Found"} />
      </div>
      <h5>{t("noRewards.title")}</h5>
      <p dangerouslySetInnerHTML={{ __html: t("noRewards.description") }}></p>
    </div>
  );
}
