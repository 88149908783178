import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "state/context";
import VendorList from "./VendorList";

function Vendors() {
  const { state } = useContext(AppContext);
  const { vendors } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    // (async () => {
    //   try {
    //     setIsLoading(true);
    //     const vendors = await getVendors();
    //     setVendors(vendors);
    //     setIsLoading(false);
    //   } catch (error) {
    //     console.log(error);
    //     setError(error);
    //   }
    // })();
    setIsLoading(false);
    setError(undefined);
  }, []);

  if (error) {
    return <div>Ops! Something went wrong.</div>;
  }

  return (
    <div>
      <div>{isLoading ? "Loading..." : <VendorList vendors={vendors} />}</div>
    </div>
  );
}

export default Vendors;
