import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { ChevronRight } from "@material-ui/icons";
import "./Onboarding.scss";
import { AppContext } from "state/context";
import { UserActionTypes } from "state/actions/actions";
import { useTranslation } from "react-i18next";

function Onboarding() {
  const { t } = useTranslation("onboarding");
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const { state, dispatch } = useContext(AppContext);
  const { user } = state;

  const slides = [
    {
      title: t("slide1.title"),
      description: t("slide1.description")
    },
    {
      title: t("slide2.title"),
      description: t("slide2.description")
    },
    {
      title: t("slide3.title"),
      description: t("slide3.description")
    },
    {
      title: t("slide4.title"),
      description: t("slide4.description")
    }
  ];

  useEffect(() => {
    setHasPrev(selectedSlideIndex % slides.length > 0);
    setHasNext(selectedSlideIndex % slides.length < slides.length - 1);
    console.log(selectedSlideIndex, slides.length, hasPrev, hasNext);
  }, [selectedSlideIndex, slides.length, hasPrev, hasNext]);

  const goToPrevious = () =>
    setSelectedSlideIndex(
      (slides.length + selectedSlideIndex - 1) % slides.length
    );
  const goToNext = () =>
    setSelectedSlideIndex(
      (slides.length + selectedSlideIndex + 1) % slides.length
    );

  const handleGetStarted = () => {
    localStorage.setItem("hasSeenOnboarding", "true");

    dispatch({
      type: UserActionTypes.UserUpdate,
      payload: { ...user, hasSeenOnboarding: true }
    });
  };

  return (
    <div className="Onboarding">
      <div className="OnboardingNavigation">
        {hasPrev ? (
          <Button
            className="OnboardingNavigationPrev"
            startIcon={<ChevronLeft />}
            size="small"
            onClick={goToPrevious}
          >
            {t("previousButton")}
          </Button>
        ) : (
          <div />
        )}
        {hasNext ? (
          <Button
            className="OnboardingNavigationNext"
            endIcon={<ChevronRight />}
            size="small"
            onClick={goToNext}
          >
            {t("nextButton")}
          </Button>
        ) : (
          <div />
        )}
      </div>
      <div className="OnboardingSlides">
        {slides.map((slide, i) => (
          <div
            className={`OnboardingSlide ${selectedSlideIndex === i && "OnboardingSlide-active"
              }`}
            key={i}
          >
            <h3>{slide.title}</h3>
            <p>{slide.description}</p>
            <div className="image"
              style={{ backgroundImage: `url(/onboarding-${i + 1}@2x-${user.language}.png)` }}></div>
          </div>
        ))}
      </div>
      <Button className="OnboardingStartButton" onClick={handleGetStarted}>
        {t("startButton")}
      </Button>
    </div>
  );
}

export default Onboarding;
