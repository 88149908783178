import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Reward, Routes, Vendor } from "types/types";
import RewardCard from "components/RewardCard";

import "./RedeemRewardConfirmation.scss";

interface Props {
  reward: Reward;
  vendor: Vendor;
  date: string;
}

function RedeemRewardConfirmation({ reward, vendor, date }: Props) {
  const { t } = useTranslation("redeemRewardConfirmation");

  return (
    <div className="RedeemRewardConfirmation">
      <div className="RedeemRewardConfirmationTitle">{t("title")}</div>
      <div
        className="RedeemRewardConfirmationSubtitle"
        dangerouslySetInnerHTML={{ __html: t("description") }}
      ></div>
      <div className="RedeemRewardConfirmationReward">
        <RewardCard reward={reward} />
      </div>
      <div className="RedeemRewardConfirmationVendor">
        <div className="RedeemRewardConfirmationVendorName">{vendor.name}</div>
        <div className="RedeemRewardConfirmationVendorTime">
          {new Date(date).toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "short",
            day: "numeric"
          })}
        </div>
      </div>

      <div className="RedeemRewardConfirmationFooter">
        <p className="RedeemRewardConfirmationInstruction">
          {t("instruction")}
        </p>
        <Link to={Routes.REWARDS}>
          <Button>{t("button")}</Button>
        </Link>
      </div>
    </div>
  );
}

export default RedeemRewardConfirmation;
