export interface Stamp {
  id: number;
  name: string;
  imgUrl: string;
  description: string;
  shortDescription: string;
  quantity?: number;
}

export interface StampListItem extends Stamp {
  disabled?: boolean;
}

export interface UserStamp {
  id: number;
  quantity: number;
}

export interface Claim {
  id: number;
  vendorId: number;
  userId: number;
  stampIds: number[];
  claimedAt: number;
}

export interface Vendor {
  id: number;
  name: string;
  description: string;
  address: string;
  stampIds: number[];
  profileUrl: string;
  imgUrls: string[];
  googleMapsLink: string;
}

export enum ChallengeState {
  Claimable = "claimable",
  Ongoing = "ongoing",
  Completed = "completed"
}

export interface Challenge {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  stampIds: number[];
  collectedStampIds: number[];
  imgUrl: string;
  vendorIds: (number | string)[];
  reward: Reward;
  percent: number;
  state: ChallengeState;
}

export interface Reward {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  redeemed?: boolean;
  redeemedOn?: number | null;
  challengeId: number;
}

export enum ClaimedRewardState {
  "Claimed" = "claimed",
  "Redeemed" = "redeemed"
}

export interface ClaimedReward {
  id: number;
  rewardId: number;
  vendorId: number | null;
  status: ClaimedRewardState;
  createdAt: string;
  updatedAt: string;
}

export interface User {
  email: string;
  token?: string;
  hasSeenOnboarding?: boolean;
  language?: string;
}

export enum Routes {
  "HOME" = "/",
  "SIGNUP" = "/signup",
  "SIGNIN" = "/signin",
  "SIGNOUT" = "/signout",
  "COLLECT" = "/collect",
  "VENDORS" = "/vendors",
  "VENDOR" = "/vendors/:id",
  "STAMPS" = "/stamps",
  "STAMP" = "/stamps/:id",
  "CHALLENGES" = "/challenges",
  "CHALLENGE" = "/challenges/:id",
  "REWARDS" = "/rewards",
  "REWARD" = "/rewards/:id",
  "CLAIM" = "/claim"
}

export enum Language {
  "English" = "en",
  "Japanese" = "ja"
}
