import axios from "axios";
import {
  Claim,
  UserStamp,
  Stamp,
  Vendor,
  User,
  Challenge,
  Reward,
  ChallengeState,
  ClaimedReward
} from "types/types";

const API_SERVER_URL = process.env.REACT_APP_API_URL;
const API_PREFIX = process.env.REACT_APP_API_PREFIX || "";

const ENDPOINTS = {
  SIGN_IN: `${API_SERVER_URL}/users/sign_in.json`,
  SIGN_UP: `${API_SERVER_URL}/users.json`,
  GET_STAMPS: `${API_SERVER_URL}${API_PREFIX}/stamps`,
  GET_MY_STAMPS: `${API_SERVER_URL}${API_PREFIX}/participant_stamps`,
  CLAIM_STAMPS: `${API_SERVER_URL}${API_PREFIX}/stamps/claim`,
  GET_VENDORS: `${API_SERVER_URL}${API_PREFIX}/vendors`,
  GET_CHALLENGES: `${API_SERVER_URL}${API_PREFIX}/challenges`,
  GET_REWARDS: `${API_SERVER_URL}${API_PREFIX}/rewards`,
  PARTICIPANT_REWARDS: `${API_SERVER_URL}${API_PREFIX}/participant_rewards`
};

const generateAuthenticationHeaders = (user: User) => {
  return {
    "X-User-Email": user.email,
    "X-User-Token": user.token,
    Language: user.language
  };
};

export function onAuthenticationRequired(callback: () => void) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        console.log("Error", error);
        callback();
      }
      return Promise.reject(error);
    }
  );
}

export function signIn(userName: string, password: string) {
  const formData = new FormData();
  formData.append("user[email]", userName);
  formData.append("user[password]", password);
  return axios.post(ENDPOINTS.SIGN_IN, formData).then((response) => {
    return response.data;
  });
}

export function signUp(userName: string, password: string) {
  const formData = new FormData();
  formData.append("user[email]", userName);
  formData.append("user[password]", password);
  return axios.post(ENDPOINTS.SIGN_UP, formData).then((response) => {
    return response.data;
  });
}

export function getStamps(user: User) {
  return axios
    .get(ENDPOINTS.GET_STAMPS, { headers: generateAuthenticationHeaders(user) })
    .then((response) => {
      return response.data.stamps.map((stamp: any) => {
        return {
          id: stamp.id,
          name: stamp.name,
          imgUrl: stamp.img_url,
          description: stamp.description,
          shortDescription: stamp.short_description
        } as Stamp;
      });
    });
}

export function getUserStamps(user: User) {
  return axios
    .get(ENDPOINTS.GET_MY_STAMPS, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      return response.data.participant_stamps.map((stamp: any) => {
        return { id: stamp.stamp_id, quantity: stamp.quantity } as UserStamp;
      });
    })
    .catch((error) => {
      console.log("Stamps API error", error);
      throw error;
    });
}

export function getStampsWithQuantity(user: User) {
  return Promise.all([getStamps(user), getUserStamps(user)]).then(
    (responses) => {
      const [stamps, myStamps] = responses;
      const stampsWithQuantity = stamps.map((stamp: Stamp) => {
        const myStamp = myStamps.find(
          (myStamp: UserStamp) => myStamp.id === stamp.id
        );
        return {
          ...stamp,
          quantity: myStamp ? myStamp.quantity : 0
        } as Stamp;
      });
      return stampsWithQuantity as Stamp[];
    }
  );
}

export function claimStamps(user: User, token: string) {
  return axios
    .post(
      ENDPOINTS.CLAIM_STAMPS,
      {
        token: token
      },
      {
        headers: generateAuthenticationHeaders(user)
      }
    )
    .then((response) => {
      const { data } = response;
      return { stampIds: data.claim.stamp_ids } as Claim;
    });
}

export function getVendors(user: User) {
  return axios
    .get(ENDPOINTS.GET_VENDORS, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      return response.data.vendors.map((vendor: any) => {
        return {
          id: vendor.id,
          name: vendor.name,
          description: vendor.description,
          address: vendor.address,
          stampIds: vendor.stamps,
          profileUrl: vendor.profile_url,
          imgUrls: vendor.img_urls,
          googleMapsLink: vendor.gmap_link
        } as Vendor;
      });
    })
    .catch((error) => {
      console.log("Vendor api error", error.response);
    });
}

export function getChallenges(user: User) {
  return axios
    .get(ENDPOINTS.GET_CHALLENGES, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      const data = response.data;
      const result: Challenge[] = ["claimable", "ongoing", "completed"]
        .map((challengeState) => {
          return data[challengeState].map((rawChallenge: any) => {
            const challenge = {
              ...rawChallenge,
              collected_stamps: rawChallenge.collected_stamps || []
            };
            const reward = challenge.reward;
            return {
              id: challenge.id,
              name: challenge.name,
              shortDescription: challenge.short_description,
              description: challenge.description,
              stampIds: challenge.stamps,
              collectedStampIds: challenge.collected_stamps,
              imgUrl: challenge.img_url,
              vendorIds: challenge.vendors.map(
                (vendor: { id: number | string }) => vendor.id
              ),
              reward: {
                id: reward.id,
                name: reward.name,
                shortDescription: reward.subtitle
              } as Reward,
              percent: Math.round(
                (challenge.collected_stamps.length / challenge.stamps.length) *
                  100
              ),
              state: challengeState as ChallengeState
            } as Challenge;
          });
        })
        .reduce((prev, current) => prev.concat(current));
      console.log("Challenges", result);
      return result;
    })
    .catch((error) => {
      console.log("Challenges API error", error);
      throw error;
    });
}

export function getRewards(user: User) {
  return axios
    .get(ENDPOINTS.GET_REWARDS, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      return response.data.rewards.map((reward: any) => {
        return {
          id: reward.id,
          name: reward.name,
          challengeId: reward.challenge_id,
          description: reward.description,
          shortDescription: reward.subtitle
        } as Reward;
      });
    })
    .catch((error) => {
      console.log("Rewards api error", error.response);
      throw error;
    });
}

export function getClaimedRewards(user: User) {
  return axios
    .get(ENDPOINTS.PARTICIPANT_REWARDS, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      return response.data.participant_rewards.map((claimedReward: any) => {
        return {
          id: claimedReward.id,
          rewardId: claimedReward.reward_id,
          vendorId: claimedReward.vendor_id,
          status: claimedReward.status,
          createdAt: claimedReward.created_at,
          updatedAt: claimedReward.updated_at
        } as ClaimedReward;
      });
    })
    .catch((error) => {
      console.log("Participant rewards api error", error.response);
    });
}

export function claimReward(user: User, rewardId: string | number) {
  const formData = {
    reward_id: rewardId
  };

  return axios
    .post(ENDPOINTS.PARTICIPANT_REWARDS, formData, {
      headers: generateAuthenticationHeaders(user)
    })
    .then((response) => {
      const claimedReward = response.data.participant_reward;
      return {
        id: claimedReward.id,
        rewardId: claimedReward.reward_id,
        vendorId: claimedReward.vendor_id,
        status: claimedReward.status,
        createdAt: claimedReward.created_at,
        updatedAt: claimedReward.updated_at
      } as ClaimedReward;
    })
    .catch((error) => {
      console.log("Rewards api error", error.response);
      throw error;
    });
}

export function redeemReward(
  user: User,
  claimedRewardId: string | number,
  vendorId: string | number
) {
  return axios
    .patch(
      `${ENDPOINTS.PARTICIPANT_REWARDS}/${claimedRewardId}`,
      {
        vendor_id: vendorId
      },
      {
        headers: generateAuthenticationHeaders(user)
      }
    )
    .then((response) => {
      const redeemReward = response.data.participant_reward;
      return {
        id: redeemReward.id,
        rewardId: redeemReward.reward_id,
        vendorId: redeemReward.vendor_id,
        status: redeemReward.status,
        createdAt: redeemReward.created_at,
        updatedAt: redeemReward.updated_at
      } as ClaimedReward;
    })
    .catch((error) => {
      console.log("Redeem reward error", error.message);
      throw error;
    });
}
