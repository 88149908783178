import { AppState } from "state/state";
import { AppActions, AppActionTypes } from "state/actions/actions";
import {
  Challenge,
  ClaimedReward,
  ClaimedRewardState,
  Reward,
  Stamp,
  User,
  UserStamp,
  Vendor
} from "types/types";

const updateStampsWithQuantity = (stamps: Stamp[], userStamps: UserStamp[]) => {
  const stampsWithQuantity = stamps.map((stamp: Stamp) => {
    const userStamp = userStamps.find(
      (userStamp: UserStamp) => userStamp.id === stamp.id
    );
    return {
      ...stamp,
      quantity: userStamp ? userStamp.quantity : 0
    } as Stamp;
  });
  return stampsWithQuantity as Stamp[];
};

const updateRewardsWithStatus = (rewards: Reward[], claimedRewards: ClaimedReward[]): Reward[] => {
  const rewardsWithStatus: Reward[] = rewards.map((reward: Reward) => {
    const claimedReward = claimedRewards.find(
      (claimedReward: ClaimedReward) => claimedReward.status === "redeemed" && claimedReward.rewardId === reward.id
      );

    return claimedReward ? {
      ...reward,
      redeemed: claimedReward.status === ClaimedRewardState.Redeemed,
      redeemedOn: Date.parse(claimedReward.updatedAt), // Convert ISO 8601 to timestamp
    } as Reward : reward
  });
  return rewardsWithStatus;
}

export const appReducer = (state: AppState, action: AppActions) => {
  console.log("Action", action);
  switch (action.type) {
    case AppActionTypes.AppLoadingUpdate:
      return {
        ...state,
        appLoading: action.payload
      };
    case AppActionTypes.LanguageUpdate:
      return {
        ...state,
        language: action.payload,
        user: { ...state.user, language: action.payload }
      };
    case AppActionTypes.StampsUpdate:
      return {
        ...state,
        stamps: updateStampsWithQuantity(
          action.payload as Stamp[],
          state.userStamps
        )
      };
    case AppActionTypes.UserUpdate:
      return {
        ...state,
        user: { ...action.payload, language: state.language } as User
      };
    case AppActionTypes.UserStampsUpdate:
      const userStamps = action.payload as UserStamp[];
      return {
        ...state,
        userStamps: userStamps,
        stamps: updateStampsWithQuantity(state.stamps, userStamps)

      };
    case AppActionTypes.RewardsUpdate:
      return {
        ...state,
        rewards: updateRewardsWithStatus(action.payload as Reward[], state.claimedRewards),
      };
    case AppActionTypes.ClaimedRewardsUpdate:
      const claimedRewards =  action.payload as ClaimedReward[]
      return {
        ...state,
        claimedRewards: claimedRewards,
        rewards: updateRewardsWithStatus(state.rewards, claimedRewards)
        };
    case AppActionTypes.VendorsUpdate:
      return { ...state, vendors: action.payload as Vendor[] };
    case AppActionTypes.ChallengesUpdate:
      return { ...state, challenges: action.payload as Challenge[] };
    default: {
      return state;
    }
  }
};
