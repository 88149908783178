import React from "react";
import { Stamp } from "types/types";
import LoadableImage from "./LoadableImage";
import "./StampCard.scss";

interface StampCardProps {
  stamp: Stamp;
  disabled?: boolean;
}

const StampCard: React.FC<StampCardProps> = ({ stamp, disabled }) => {
  return (
    <div className={`StampCard ${disabled && "StampCard-disabled"}`}>
      <div className="StampCardImage">
        <LoadableImage src={stamp.imgUrl} alt={stamp.name} />
      </div>
      <div className="StampCardContent">
        <div className="StampCardTitle">{stamp.name}</div>
      </div>
      {!!stamp.quantity && (
        <div className="StampCardNumber">{stamp.quantity}</div>
      )}
    </div>
  );
};

export default StampCard;
