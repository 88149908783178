import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
} from "@material-ui/core";
import LandingLayout from "./LandingLayout";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorIcon from "@material-ui/icons/Error";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { AppContext } from "state/context";
import { Routes } from "types/types";
import "./Signup.scss";

function Signup() {
  const { t } = useTranslation("signup");
  const history = useHistory();
  const { signUp } = useContext(AppContext);
  const [hasError, setHasError] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealRepeatPassword, setRevealRepeatPassword] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("form.email.errorInvalid"))
      .required(t("form.email.errorRequired")),
    password: yup
      .string()
      .required(t("form.password.errorRequired"))
      .min(6, t("form.password.errorMinLength")),
    passwordConfirmation: yup
      .string()
      .required(t("form.passwordConfirmation.errorRequired"))
      .oneOf(
        [yup.ref("password"), null],
        t("form.passwordConfirmation.errorMatch")
      ),
    acceptTCs: yup
      .boolean()
      .oneOf([true], t("form.termsAndConditions.errorRequired")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      acceptTCs: false,
    },
    isInitialValid: false,
    validationSchema,
    onSubmit: (values) => {
      console.log("Form values", values);
    },
  });

  const handleSubmit = async () => {
    console.log("Values", formik.values);
    try {
      await signUp(formik.values.email, formik.values.password);
      history.push(Routes.HOME);
    } catch (error) {
      console.log("Error registering", (error as Error).message);
      setHasError(true);
    }
  };

  const handleTogglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  const handleToggleRepeatPassword = () => {
    setRevealRepeatPassword(!revealRepeatPassword);
  };

  const handleOnFormChange = () => {
    setHasError(false);
  };

  return (
    <LandingLayout>
      <h4 className="LandingLayoutQuote">{t("description")}</h4>

      <div className="Signup">
        <form onChange={handleOnFormChange}>
          <div className="FieldRow">
            <InputBase
              id="email"
              placeholder={t("form.email.placeholder")}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${formik.touched.email && formik.errors.email ? "error" : ""
                }`}
            />

            {/* If error with email or password, display warning icon */}
            {formik.touched.email && formik.errors.email ? (
              <ErrorIcon className="FieldRowErrorIcon" />
            ) : null}

            {formik.touched.email && formik.errors.email && (
              <p className="FieldRowError">{formik.errors.email}</p>
            )}
          </div>
          <div className="FieldRow">
            <InputBase
              id="password"
              type={revealPassword ? "text" : "password"}
              placeholder={t("form.password.placeholder")}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${(formik.touched.password && formik.errors.password) ||
                (formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation)
                ? "error"
                : ""
                }`}
            />
            {revealPassword ? (
              <VisibilityIcon
                className="togglePassword"
                onClick={handleTogglePassword}
              />
            ) : (
              <VisibilityOffIcon
                className="togglePassword"
                onClick={handleTogglePassword}
              />
            )}

            {formik.touched.password && formik.errors.password && (
              <p className="FieldRowError">{formik.errors.password}</p>
            )}
          </div>
          <div className="FieldRow">
            <InputBase
              id="passwordConfirmation"
              type={revealRepeatPassword ? "text" : "password"}
              placeholder={t("form.passwordConfirmation.placeholder")}
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
                ? "error"
                : ""
                }`}
            />
            {revealRepeatPassword ? (
              <VisibilityIcon
                className="togglePassword"
                onClick={handleToggleRepeatPassword}
              />
            ) : (
              <VisibilityOffIcon
                className="togglePassword"
                onClick={handleToggleRepeatPassword}
              />
            )}

            {formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation && (
                <p className="FieldRowError BottomError">
                  {formik.errors.passwordConfirmation}
                </p>
              )}
          </div>

          <FormControlLabel
            className="SignupCheckbox"
            control={
              <Checkbox
                id="acceptTCs"
                value={formik.values.acceptTCs}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t("form.termsAndConditions.label"),
                }}
              ></span>
            }
          />
          {formik.touched.acceptTCs && formik.errors.acceptTCs && (
            <p className="FieldRowError BottomError">
              {formik.errors.acceptTCs}
            </p>
          )}
          {hasError && (
            <p className="FieldRowError BottomError">
              {t("form.errorGeneric")}
            </p>
          )}
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            {t("form.submitButton")}
          </Button>
        </form>
        <div className="SignupLogin">
          {t("signup.description")}
          <br />
          <Link to="/signin">{t("signup.link")}</Link>
        </div>
      </div>
    </LandingLayout>
  );
}

export default Signup;
