import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import { Button, Link } from "@material-ui/core";
import StampList from "components/StampList";
import LoadableImage from "components/LoadableImage";
import "./Vendor.scss";
import googleMapsPin from "assets/google-maps-pin@2x.png";

interface ParamTypes {
  id: string;
}

function VendorPage() {
  const { t } = useTranslation("vendorPage");
  const { id } = useParams<ParamTypes>();
  const { state } = useContext(AppContext);
  const { vendors, stamps } = state;
  const vendor = vendors.find((vendor) => vendor.id === parseInt(id));
  const stampsAvailable = vendor
    ? stamps.filter((stamp) => vendor.stampIds.includes(stamp.id))
    : [];

  return (
    <div className="Vendor">
      {vendor ? (
        <div>
          <div className="VendorImage">
            <LoadableImage src={vendor.profileUrl} alt={vendor.name} />
          </div>
          <h3>{vendor.name}</h3>
          <div className="VendorAddress">{vendor.address}</div>
          <Link href={vendor.googleMapsLink}>
            <Button
              className="VendorGoogleMapsButton"
              size="large"
              startIcon={<img src={googleMapsPin} alt="" />}
            >
              {t("googleMapsButton")}
            </Button>
          </Link>
          <hr />
          <div className="VendorDescription">{vendor.description}</div>
          <hr />
          <div className="VendorStampsTitle">{t("stampsTitle")}</div>
          <StampList stamps={stampsAvailable} />
        </div>
      ) : (
        <div>{t("errorDoesntExist")}</div>
      )}
    </div>
  );
}

export default VendorPage;
