import { useHistory } from "react-router-dom";
import { Routes, Challenge, ChallengeState } from "types/types";
import ChallengeCard from "./ChallengeCard";
import "./ChallengeList.scss";

interface ChallengeListProps {
  challenges: Challenge[];
}

function ChallengeList(props: ChallengeListProps) {
  const history = useHistory();
  const { challenges } = props;

  const handleClick = (challenge: Challenge) => {
    if (!challenge) {
      return null;
    }

    if (challenge.state === ChallengeState.Claimable) {
      history.push({
        pathname: Routes.CLAIM,
        state: { challengeId: challenge.id }
      });
    } else {
      history.push(Routes.CHALLENGE.replace(":id", challenge.id.toString()));
    }
  };

  return (
    <div className="ChallengeList">
      {challenges.map((challenge) => (
        <div
          className="ChallengeListItem"
          onClick={() => {
            handleClick(challenge);
          }}
          key={challenge.id}
        >
          <ChallengeCard challenge={challenge} />
        </div>
      ))}
    </div>
  );
}

export default ChallengeList;
