import { CircularProgress } from "@material-ui/core";
import { ReactNode } from "react";

import "./Loadable.scss";

interface Props {
  isLoading: boolean;
  isError: boolean;
  children: ReactNode;
}

function Loadable({ isLoading, isError, children }: Props) {
  if (isLoading) {
    return (
      <div className="Loadable Loadable-spinner">
        <CircularProgress />
      </div>
    );
  } else if (isError) {
    return <div>An error occurred. Try again later!</div>;
  } else {
    return <div className="Loadable">{children}</div>;
  }
}

export default Loadable;
