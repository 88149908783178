import React from "react";
import { Stamp } from "types/types";
import LoadableImage from "./LoadableImage";
import "./StampCardWide.scss";

interface StampCardProps {
  stamp: Stamp;
}

const StampCard: React.FC<StampCardProps> = ({ stamp }) => {
  return (
    <div className="StampCardWide">
      <div className="StampCardWideContent">
        <div className="StampCardWideTitle">{stamp.name}</div>
        <div className="StampCardWideDescription">{stamp.shortDescription}</div>
      </div>
      <div className="StampCardWideImage">
        <LoadableImage src={stamp.imgUrl} alt={stamp.name} />
      </div>
    </div>
  );
};

export default StampCard;
