import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Challenge, ChallengeState } from "types/types";
import "./ChallengeCard.scss";
import ChallengeImage from "components/ChallengeImage";
import ProgressBar from "components/ProgressBar";

interface ChallengeCardProps {
  challenge: Challenge;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge }) => {
  const { t } = useTranslation("challengeCard");
  const renderFooterAvailable = () => {
    return (
      <div className="ChallengeCardFooterAvailable">
        <div className="ChallengeCardRatio">
          {`${challenge.collectedStampIds.length}/${challenge.stampIds.length}`}
        </div>
        <div className="ChallengeCardProgress">
          <ProgressBar percent={challenge.percent} dark tall />
        </div>
        <div className="ChallengeCardReward">{challenge.reward.name}</div>
      </div>
    );
  };

  return (
    <div
      className="ChallengeCard"
      style={{ "--image-url": `url(${challenge.imgUrl}` } as any}
    >
      <div className="ChallengeCardContent">
        <div className="ChallengeCardImage">
          <ChallengeImage src={challenge.imgUrl} alt={challenge.name} />
        </div>
        <div className="ChallengeCardCopy">
          <div className="ChallengeCardTitle">{challenge.name}</div>
          <div className="ChallengeCardSubtitle">
            {challenge.shortDescription}
          </div>
        </div>
      </div>
      <div className="ChallengeCardFooter">
        {(() => {
          switch (challenge.state) {
            case ChallengeState.Claimable:
              return (
                <Button size="small" className="MuiButton-gradient">
                  {t("claimButton")}
                </Button>
              );

            case ChallengeState.Completed:
              return <Button size="small">{t("claimed")}</Button>;
            default:
              return renderFooterAvailable();
          }
        })()}
      </div>
    </div>
  );
};

export default ChallengeCard;
