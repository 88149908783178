import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import { Challenge, ChallengeState } from "types/types";
import ChallengeList from "components/ChallengesList";
import "./Challenges.scss";

function Challenges() {
  const { t } = useTranslation("challenges");
  const { state } = useContext(AppContext);
  const { challenges } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [claimableChallenges, setClaimableChallenges] = useState<Challenge[]>(
    [] as Challenge[]
  );
  const [availableChallenges, setAvailableChallenges] = useState<Challenge[]>(
    [] as Challenge[]
  );
  const [pastChallenges, setPastChallenges] = useState<Challenge[]>(
    [] as Challenge[]
  );

  const challengeLists = [
    {
      key: "completed",
      title: t("completedTitle"),
      list: claimableChallenges
    },
    { key: "available", title: t("availableTitle"), list: availableChallenges },
    { key: "past", title: t("pastTitle"), list: pastChallenges }
  ];

  useEffect(() => {
    if (!challenges) {
      return;
    }

    setClaimableChallenges(
      challenges.filter(
        (challenge) => challenge.state === ChallengeState.Claimable
      )
    );
    setAvailableChallenges(
      challenges.filter(
        (challenge) => challenge.state === ChallengeState.Ongoing
      )
    );
    setPastChallenges(
      challenges.filter(
        (challenge) => challenge.state === ChallengeState.Completed
      )
    );

    setIsLoading(false);
    setError(undefined);
  }, [challenges]);

  if (error) {
    return <div>Ops! Something went wrong.</div>;
  }

  return (
    <div className="Challenges">
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="ChallengesListContainer">
          {challengeLists
            .filter((challengeList) => challengeList.list.length > 0)
            .map((challengeList) => {
              return (
                <div
                  className={`ChallengesList ChallengesList-${challengeList.key}`}
                  key={challengeList.key}
                >
                  <p className="SectionTitle">{challengeList.title}</p>
                  <ChallengeList challenges={challengeList.list} />
                  <hr />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default Challenges;
