import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import { ClaimedReward, Routes, User } from "types/types";
import RewardCard from "./RewardCard";
import { claimReward } from "services/apis";
import { useLoad } from "state/hooks/useLoad";
import ChallengeImage from "./ChallengeImage";
import { Button } from "@material-ui/core";
import Loadable from "./Loadable";
import "./ClaimReward.scss";

interface LocationState {
  challengeId: number;
}

function ClaimRewardPage() {
  const { t } = useTranslation("claimReward");
  const location = useLocation();
  const params = location.state as LocationState;
  const { state, loadData } = useContext(AppContext);
  const { user, challenges } = state;
  const [claimedReward, setClaimedReward] = useState<ClaimedReward | null>(
    null
  );

  const challenge = challenges.find(
    (challenge) => challenge.id === params.challengeId
  );

  const claimAndReload = async (user: User, rewardId: number) => {
    try {
      const result = await claimReward(user, rewardId);
      await loadData(user, false);
      setClaimedReward(result);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const [triggerClaimAndReload, { isLoading, isError }] = useLoad(
    claimAndReload,
    1000
  );

  // Prevent having to add triggerClaimReward as dependency of useEffect,
  // which leads to infinite loop
  const triggerClaimAndReloadRef = useRef(triggerClaimAndReload);

  useEffect(() => {
    async function fetch(rewardId: number) {
      try {
        const claimedReward = await triggerClaimAndReloadRef.current(
          user,
          rewardId
        );
        console.log("Claimed reward", claimedReward);
      } catch (error) {
        console.log("Error", error);
      }
    }

    if (challenge?.reward.id && user) {
      fetch(challenge.reward.id);
    }
  }, [challenge?.reward.id, user]);

  return (
    <div className="ClaimReward">
      {challenge && (
        <Loadable isLoading={isLoading} isError={isError}>
          <div
            className="ClaimRewardTitle"
            dangerouslySetInnerHTML={{ __html: t("title") }}
          ></div>
          <div className="ClaimRewardChallenge">
            <ChallengeImage src={challenge.imgUrl} alt={challenge.name} big />
            <div className="ClaimRewardChallengeName">{challenge.name}</div>
          </div>
          <div className="ClaimRewardReward">
            <p>{t("rewardTitle")}</p>
            <RewardCard reward={challenge.reward} />
          </div>
          {claimedReward && (
            <Link
              to={Routes.REWARD.replace(
                ":id",
                claimedReward.rewardId.toString()
              )}
            >
              <Button>{t("button")}</Button>
            </Link>
          )}
        </Loadable>
      )}
    </div>
  );
}

export default ClaimRewardPage;
