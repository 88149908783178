import { Button, InputBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "state/context";
import * as yup from "yup";
import { useFormik } from "formik";
import { Routes } from "types/types";
import LandingLayout from "components/LandingLayout";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorIcon from "@material-ui/icons/Error";
import "./Signin.scss";

function Signin() {
  const { t } = useTranslation("signin");
  const history = useHistory();
  const { signIn } = useContext(AppContext);
  const [hasError, setHasError] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("form.email.errorInvalid"))
      .required(t("form.email.errorRequired")),
    password: yup.string().required(t("form.password.errorRequired")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    isInitialValid: false,
    validationSchema,
    onSubmit: (values) => {
      console.log("Form values", values);
    },
  });

  const handleSubmit = async () => {
    console.log("Values", formik.values);
    try {
      await signIn(formik.values.email, formik.values.password);
      history.push(Routes.HOME);
    } catch (error) {
      console.log("Error logging in", (error as Error).message);
      setHasError(true);
    }
  };

  const handleTogglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  // insure error is false while user is updating the form
  const handleOnChange = () => {
    setHasError(false);
  };

  return (
    <LandingLayout>
      <h4 className="LandingLayoutQuote">{t("description")}</h4>

      <div className="Signin">
        <form onChange={handleOnChange}>
          <div className="FieldRow">
            <InputBase
              id="email"
              placeholder={t("form.email.placeholder")}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${(formik.touched.email && formik.errors.email) || hasError
                ? "error"
                : ""
                }`}
            />

            {/* If error with email or password, display warning icon */}
            {(formik.touched.email && formik.errors.email) ||
              (formik.touched.password && formik.errors.password) ||
              hasError ? (
              <ErrorIcon className="FieldRowErrorIcon" />
            ) : null}

            {/* Error message for email error only */}
            {(formik.touched.email || formik.touched.password) &&
              formik.errors.email && (
                <p className="FieldRowError">{formik.errors.email}</p>
              )}
          </div>
          <div className="FieldRow">
            <InputBase
              id="password"
              placeholder={t("form.password.placeholder")}
              type={revealPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${(formik.touched.password && formik.errors.password) ||
                hasError
                ? "error"
                : ""
                }`}
            />
            {revealPassword ? (
              <VisibilityIcon
                className="togglePassword"
                onClick={handleTogglePassword}
              />
            ) : (
              <VisibilityOffIcon
                className="togglePassword"
                onClick={handleTogglePassword}
              />
            )}

            {/* Error message for password only*/}
            {formik.touched.password && formik.errors.password && (
              <p className="FieldRowError BottomError">
                {formik.errors.password}
              </p>
            )}

            {/* Error message for email/password mismatch */}
            {hasError && (
              <p className="FieldRowError BottomError">
                {t("form.errorGeneric")}
              </p>
            )}
          </div>

          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            {t("form.submitButton")}
          </Button>
        </form>
        <Link className="SigninReset" to="/password-reset">
          {t("signup.reset")}
        </Link>
        <div className="SigninRegister">
          {t("signup.description")}
          <br />
          <Link to="/signup">{t("signup.link")}</Link>
        </div>
      </div>
    </LandingLayout>
  );
}

export default Signin;
