import { Stamp } from "types/types";

export enum StampsActionTypes {
  StampsUpdate = "stamps/update",
  StampsWithQuantityGet = "stampswithquantity/get"
}

export interface StampsUpdate {
  type: StampsActionTypes.StampsUpdate;
  payload: Stamp[];
}

export interface StampsWithQuantityGet {
  type: StampsActionTypes.StampsWithQuantityGet;
}

export type StampsActions = StampsUpdate | StampsWithQuantityGet;
