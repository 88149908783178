import { useState } from "react";
import { useHistory, useLocation, matchPath } from "react-router";
import { Tab, Tabs } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Routes } from "types/types";
import "./Navigation.scss";
import { useTranslation } from "react-i18next";

const secondLevelRoutes = [
  Routes.CHALLENGE,
  Routes.STAMP,
  Routes.VENDOR,
  Routes.REWARD
];

const Navigation = () => {
  const { t } = useTranslation("navigation");
  const history = useHistory();
  const location = useLocation();

  const tabs: { label: string; route: string }[] = [
    { label: t("stamps"), route: Routes.STAMPS },
    { label: t("challenges"), route: Routes.CHALLENGES },
    { label: t("rewards"), route: Routes.REWARDS },
    { label: t("vendors"), route: Routes.VENDORS }
  ];

  const [selectedTab, setSelectedTab] = useState<number>(
    tabs.findIndex((tab) => tab.route === location.pathname)
  );

  const isSecondLevelRoute = secondLevelRoutes.reduce((prev, current) => {
    const match =
      matchPath(location.pathname, {
        path: current,
        exact: true,
        strict: false
      }) !== null;
    return prev || match;
  }, false);

  const handleGoBack = () => {
    console.log("Location", history.location.pathname);
    // For the subroutes, removed the last part of the path containing the entity ID
    // Eg. /challenges/1 -> /challenges/
    const regexResult = /(\/[A-z]+\/).*/.exec(history.location.pathname);
    if (regexResult) {
      const prevRoute = regexResult[1];
      history.push(prevRoute);
    }
  };

  return (
    <div className="Navigation">
      {isSecondLevelRoute ? (
        <div className="NavigationBack" onClick={handleGoBack}>
          <ChevronLeftIcon />
          {t("backButton")}
        </div>
      ) : (
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="off"
          onChange={(e, value) => {
            setSelectedTab(value);
            history.push(tabs[value].route);
          }}
          aria-label="navigation links"
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} key={index} />
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default Navigation;
