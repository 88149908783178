import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import { ClaimedRewardState, Reward } from "types/types";
import RewardList from "./RewardList";
import RewardEmpty from "./RewardEmpty";
import "./Rewards.scss";

function Rewards() {
  const { t } = useTranslation("rewards");
  const { state } = useContext(AppContext);
  const { rewards, claimedRewards } = state;
  const [availableRewards, setAvailableRewards] = useState<Reward[] | null>(null);
  const [redeemedRewards, setRedeemedRewards] = useState<Reward[] | null>(null);

  useEffect(() => {
    if (rewards && claimedRewards) {
      const availableRewards: Reward[] = claimedRewards
        .filter(
          (claimedReward) => claimedReward.status === ClaimedRewardState.Claimed
        )
        .map((claimedReward) => {
          return rewards.find(
            (reward) => reward.id === claimedReward.rewardId
          ) as Reward;
        });
      availableRewards.length > 0 && setAvailableRewards(availableRewards);

      const redeemedRewards: Reward[] = claimedRewards
        .filter(
          (claimedReward) => claimedReward.status === ClaimedRewardState.Redeemed
        )
        .map((claimedReward) => {
          return rewards.find(
            (reward) => reward.id === claimedReward.rewardId
          ) as Reward;
        });
      redeemedRewards.length > 0 && setRedeemedRewards(redeemedRewards);
    }
  }, [rewards, claimedRewards]);

  return (
    <div className="Rewards">
      {!(availableRewards || redeemedRewards) && <RewardEmpty />}

      {availableRewards && (
        <>
          <div className="RewardsList">
            <div className="RewardsListTitle">{t("availableListTitle")}</div>
            <div>{availableRewards && <RewardList rewards={availableRewards} />}</div>
            <hr />
          </div>
        </>
      )}

      {redeemedRewards && (
        <>
          <div className="RewardsCardList">
            <div className="RewardsListTitle">{t("redeemedListTitle")}</div>

            <div>{redeemedRewards && <RewardList rewards={redeemedRewards} />}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default Rewards;
