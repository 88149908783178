import { useTranslation } from "react-i18next";
import logo from "assets/kammeko-logo-black.svg";
import qrCode from "assets/desktop-qr-code.svg";
import "./DesktopAlert.scss";

function DesktopAlert() {
  const { t } = useTranslation("site");
  return (
    <div className="DesktopAlert">
      <p
        className="DesktopAlertDescription"
        dangerouslySetInnerHTML={{ __html: t("desktopAlert") }}
      ></p>
      <div className="DesktopAlertQrCode">
        <img src={qrCode} alt="qr code to mobile app" />
      </div>
      <img className="DesktopAlertLogo" src={logo} alt="kammeko logo" />
    </div>
  );
}

export default DesktopAlert;
