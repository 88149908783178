import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "state/context";
import VendorList from "components/VendorList";
import LoadableImage from "components/LoadableImage";
import StampList from "./StampList";
import RewardCard from "./RewardCard";
import {
  Challenge,
  ChallengeState,
  Reward,
  Routes,
  Stamp,
  StampListItem,
  Vendor
} from "types/types";
import "./Challenge.scss";

interface ParamTypes {
  id: string;
}

function ChallengePage() {
  const { t } = useTranslation("challengePage");
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const { state } = useContext(AppContext);
  const { challenges, stamps, vendors, rewards } = state;

  const [stampListItems, setStampListItems] = useState<Stamp[]>([] as Stamp[]);
  const [challengeVendors, setChallengeVendors] = useState<Vendor[]>(
    [] as Vendor[]
  );
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [reward, setReward] = useState<Reward | null>(null);

  useEffect(() => {
    const challenge = challenges.find(
      (challenge) => challenge.id === parseInt(id)
    );
    setChallenge(challenge || null);
  }, [challenges, id]);

  useEffect(() => {
    if (challenge && rewards) {
      const reward = rewards.find((reward) => reward.id === challenge.reward.id);
      setReward(reward || null);
    }
  }, [challenge, rewards]);

  const handleClickReward = () => {
    if (!challenge) {
      return;
    }

    console.log("Challenge", challenge);

    if (challenge.state === ChallengeState.Completed) {
      console.log("Claiming reward");
    } else {
      history.push(
        Routes.REWARD.replace(":id", challenge.reward.id.toString())
      );
    }
  };

  useEffect(() => {
    if (!challenge) {
      return;
    }

    let tempCollectedStampIds = [...challenge.collectedStampIds];
    console.log("Challenge", challenge);
    setStampListItems(
      challenge.stampIds.map((stampId) => {
        const stamp = stamps.find((stamp) => stamp.id === stampId);
        const collectedStampId = tempCollectedStampIds?.indexOf(stampId);
        const isStampCollected = collectedStampId > -1;
        isStampCollected && tempCollectedStampIds.splice(collectedStampId, 1);
        console.log(
          "temp collected stamps",
          stampId,
          tempCollectedStampIds,
          isStampCollected
        );
        return {
          ...stamp,
          quantity: 0,
          disabled: !isStampCollected
        } as StampListItem;
      })
    );
  }, [stamps, challenge]);

  useEffect(() => {
    if (challenge && vendors) {
      setChallengeVendors(
        challenge.vendorIds.map((id) =>
          vendors.find((vendor) => vendor.id === id)
        ) as Vendor[]
      );
    }
  }, [challenge, vendors]);

  return (
    <div className="Challenge">
      {challenge ? (
        <div>
          <div className="ChallengeImage">
            <LoadableImage src={challenge.imgUrl} alt={challenge.name} />
          </div>
          <h3>{challenge.name.replace("<br />", "")}</h3>

          <div className="ChallengeShortDescription">
            {challenge.shortDescription}
          </div>
          <div className="ChallengeStamps">
            {console.log("Pre call", stampListItems as Stamp[])}
            {stampListItems && <StampList stamps={stampListItems as Stamp[]} />}
          </div>
          {reward && (
            <div className="ChallengeReward" onClick={handleClickReward}>
              <RewardCard reward={reward} />
            </div>
          )}
          <hr />
          <div className="ChallengeDescription">
            <div className="ChallengeDescriptionTitle">{t("")}</div>
            {challenge.description}
          </div>
          <hr />
          <div className="ChallengeVendorsTitle">{t("availableFromTitle")}</div>
          <VendorList vendors={challengeVendors} />
        </div>
      ) : (
        <div>This challenge doesn't exist!</div>
      )}
    </div>
  );
}

export default ChallengePage;
