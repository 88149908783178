import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

interface LoadableImageProps {
  src: string;
  [prop: string]: any;
}

const LoadableImage: React.FC<LoadableImageProps> = ({ src, ...rest }) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setIsImageLoaded(true);
    };
  }, [src]);

  return isImageLoaded ? (
    <img src={src} alt="" {...rest} />
  ) : (
    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
  );
};

export default LoadableImage;
