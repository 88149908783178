import { ReactNode } from "react";
import logo from "assets/kammeko-logo-black.svg";
import "./LandingLayout.scss";
import LanguageSwitch from "./LanguageSwitch";

interface Props {
  children: ReactNode;
}

function LandingLayout({ children }: Props) {
  return (
    <div className="LandingLayout">
      <div className="LandingLayoutLogo">
        <img src={logo} alt="Kammeko logo" />
      </div>

      {children}

      <div className="LandingLayoutLanguage">
        <LanguageSwitch size='small' />
      </div>
    </div>
  );
}

export default LandingLayout;
